#portfolio {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#portfolio-title {
  padding-top: 1em;
}

#links {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 2em;
}

#links img {
  max-height: 10vh;
}

#carousel {
  min-width: 100%;
}

@media screen and (min-width: 910px) {
  #portfolio-title {
    font-size: 4em;
  }
}
