#landing {
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#avatar {
  padding: 2em 0 0 0;
}

#avatar img {
  border-radius: 50%;
  max-height: 25vh;
}

#title {
  padding: 2em 0 0 0;
}

#about {
  padding: 0 1em 2em 1em;
}

#arrow {
  display: none;
}

#skills {
  padding: 0 0 1em 0;
}

#skills-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@media screen and (min-width: 910px) {
  #avatar {
    padding: 3em 0 0 0;
  }

  #avatar img {
    height: 30vh;
  }

  #title h1 {
    padding: 0 0 0.5em 0;
    font-size: 4em;
  }

  #arrow {
    display: block;
    height: 4em;
    margin-bottom: 1em;
  }

  #about {
    max-width: 40vw;
  }

  #skills {
    font-size: 2em;
  }
}
